.platinum-shop-container {
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr;
	box-sizing: border-box;
}

.platinum-shop-container-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	line-height: 30px;
	column-gap: 10px;
	row-gap: 10px;
}

.platinum-shop-container-button {
	cursor: pointer;
	padding: 5px;
	min-width: 100px;
	height: 40px;
}

.mollie-card-holder-containe {
	flex-basis: 60%;
}

.mollie-field-error {
	font-size: 12px;
	margin-top: 2px;
	color: #ff5555;
	font-weight: 500;
	height: 14.5px;
}

.theme-platinum-shop {
	background-image: url(/images/premium/platinum_cave.png);
}

.mollie-component {
	padding: 5px;
	margin: 5px;
	transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
	color: #fff;

	background-image: /* linear-gradient(to bottom, rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.329)), */ url(/images/ui/frame_panel.png);
	background-size: 100% 100%;
	height: 32px;
	/* padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 7px;
	padding-right: 7px;
	position: relative; */
}

.mollie-component > iframe {
	height: 22px !important;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	box-shadow: 0 0 10px 5px rgb(0 0 0 / 50%), 0 0 5px 5px rgb(0 0 0 / 50%) inset !important;
}

.mollie-component.has-focus {
	box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5), 0 0 2px 2px rgba(255, 255, 255, 0.5) inset !important;
}

.mollie-component.is-invalid {
	box-shadow: 0 0 2px 2px rgba(255, 85, 85, 0.5), 0 0 2px 2px rgba(255, 85, 85, 0.5) inset !important;
}

.mollie-component.is-valid {
	box-shadow: 0 0 2px 2px rgba(140, 252, 136, 0.5), 0 0 2px 2px rgba(140, 252, 136, 0.5) inset !important;
}

/* .theme-platinum-shop::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(/images/premium/platinum_cave.png);
	background-size: cover;
	z-index: -1;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
} */

/* .play-area-background-theme-platinum-shop::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 97.5%;
	height: 97%;
	margin-left: 1.25%;
	margin-top: 1.5%;
	background-image: url(/images/premium/platinum_cave.png);
	background-size: cover;
	z-index: -1;
	background-position: center;
	background-repeat: no-repeat;
		filter: drop-shadow(0 0 0.45rem black);
	border-radius: 5px;
	box-shadow: 0 0 4px 4px rgb(21 23 27) inset;
	animation: zoomin 5s;
} */

/* @keyframes zoomin {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.5);
	}
} */

/* .testi-back {
	position: absolute;
	height: 100%;
	width: 100%;
	background-image: url(/images/premium/platinum_cave.png);
	background-size: cover;
	z-index: -1;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
} */

.platinum-pack-list {
	display: grid;
	/* grid-template-columns: repeat(auto-fit, 200px); */
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	/* grid-template-rows: repeat(auto-fit, minmax(150px, 1fr)); */
	grid-gap: 1rem;
}

.platinum-pack {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	text-align: center;
	padding: 5px;
	will-change: scale;
	transition: all 0.2s;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));

	/* border-radius: 10px; */
}

.platinum-pack.idlescape-container {
	border-image-width: 80px !important;
}

.platinum-pack:hover {
	scale: 1.05;
	cursor: pointer;
	/* border: 1px solid rgba(0, 136, 199, 0.5); */
	box-shadow: 0 0 20px 5px rgba(54, 188, 250, 0.8), inset 0 0 20px 0 rgba(54, 188, 250, 0.8);
}

.platinum-pack:hover > img {
	scale: 1.15;
}

.platinum-pack > img {
	max-width: 200px;
	width: 100%;
	min-width: 100px;
	margin-left: auto;
	margin-right: auto;
	padding: 30px 30px 5px;
	transition: scale 0.2s;
	/* padding-top: 5px; */
	background: rgba(0, 0, 0, 0);
	background: radial-gradient(circle at 50%, rgba(54, 188, 250, 0.8) 0, rgba(0, 136, 199, 0.5) 30%, transparent 75%);
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 1));
	/* background: radial-gradient(ellipse at 50% 40%, rgba(0, 136, 199, 0.5), transparent 50%),
		radial-gradient(ellipse at 50% 60%, rgba(0, 136, 199, 0.6) 0, transparent 50%); */
	/* width: 60%;
	margin-left: auto;
	margin-right: auto; */
}

.platinum-pack-main-title {
	font-size: 48px;
	text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgba(54, 188, 250, 0.8), 0 0 40px rgba(54, 188, 250, 0.8),
		0 0 50px rgba(54, 188, 250, 0.8), 0 0 60px rgba(54, 188, 250, 0.8), 0 0 70px #e60073;
}
.platinum-pack-sub-title {
	font-size: 16px;
}

.platinum-pack-sub-title > img {
	height: 20px;
	margin-right: 5px;
}

.platinum-pack-price {
	font-size: 24px;
}

.platinum-theme-background-color {
	background-color: rgba(0, 0, 0, 0.8);
}

.platinum-shop-buffs-container > div {
	background-color: rgba(0, 0, 0, 0.8);
}

.platinum-pack-discount {
	position: absolute;
	right: 20px;
}

.fade-enter > div {
	opacity: 0;
}
.fade-enter-active > div {
	opacity: 1;
}
.fade-exit > div {
	opacity: 1;
}
.fade-exit-active > div {
	opacity: 0;
}
.fade-enter-active > div,
.fade-exit-active > div {
	transition: opacity 200ms;
}

.platinum-pack-purchase-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	text-align: center;
	will-change: scale;
	transition: all 0.2s;
	/* border-radius: 10px; */
}

.platinum-pack-purchase-container > div {
	width: 50%;
}

.platinum-pack-purchase-container > img {
	width: 30%;
}

.platinum-shop-container-content {
	padding: 15px;
}

.platinum-shop-purchase-review-container {
	box-sizing: border-box;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.platinum-shop-purchase-review-title {
	width: 100%;
	font-size: 48px;
	text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%),
		0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073;
}

.platinum-shop-purchase-review-image-area {
	width: 40%;
	float: left;
}

.platinum-shop-purchase-review-image-area > img {
	width: 100%;
}

.platinum-shop-purchase-review-info {
	font-size: 1.2em;
	text-align: end;
	margin-top: 20px;
}

.platinum-shop-purchase-review-info span {
	color: rgba(0, 136, 199, 1);
}

.platinum-shop-purchase-review-info ul {
	/* text-align: start; */
	color: rgba(0, 136, 199, 1);
	font-size: 1em;
	margin-top: 5px;
}

.platinum-shop-purchase-review-actions {
	display: flex;
	width: 100%;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 30px;
	height: 30px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	margin: 3px;
	border: 3px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.platinum-shop-purchase-review-button-label {
	font-size: 12px;
	color: gray;
}
